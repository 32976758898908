<template>
  <v-expand-x-transition hide-on-leave>
    <v-row class="fill-height" no-gutters>
      <v-col cols="12" md="5" class="header-space justify-center flex-column d-flex align-center">
        <space-form-illustration v-if="$vuetify.breakpoint.lgAndUp" :title="title" :subtitle="subtitle" />
        <space-form-illustration-mobile v-else :title="title" :subtitle="subtitle" />
      </v-col>
      <v-col cols="12" md="7" class="card-space d-flex flex-column justify-center px-4 px-md-11 pt-md-8 pt-lg-0">
        <slot />
      </v-col>
    </v-row>
  </v-expand-x-transition>
</template>

<script>
  import SpaceFormIllustrationMobile from '@/modules/spaces/components/Form/SpaceFormIllustrationMobile'
  import SpaceFormIllustration from '@/modules/spaces/components/Form/SpaceFormIllustration'

  export default {
    name: 'SpaceFormStep',
    components: {SpaceFormIllustration, SpaceFormIllustrationMobile},
    props: {
      step: {type: String, required: true},
      title: {type: String, required: true},
      subtitle: String
    }
  }
</script>
