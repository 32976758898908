<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-autocomplete
    v-model="establishment"
    label="Sélectionner un établissement"
    class="mr-2 elevation-x"
    item-text="name"
    item-value="uid"
    no-filter hide-details solo flat
    :items="items"
    :loading="loading"
    :search-input.sync="terms"
    @change="$emit('input', establishment)"
  />
</template>

<script>
  import {debounce} from 'throttle-debounce'
  import {empty} from '@/utils/variables'
  import {search} from '@/api/establishments'

  export default {
    name: 'EstablishmentSelectField',
    props: {
      value: String
    },

    data(){
      return {
        loading: false,
        establishment: this.value,
        terms: '',
        items: []
      }
    },

    watch: {
      terms: debounce(800, function(){this.fetchEstablishments()})
    },

    methods: {
      fetchEstablishments(){
        if(empty(this.terms)){
          this.items = []
          this.establishment = ''
          this.$emit('input', '')
          return
        }

        let item = this.items.find(v => this.terms === v.name) || null

        if(item){
          return
        }

        this.loading = true

        search(this.terms)
          .then(response => {
            this.items = response.data
          })
          .catch(this.$handleApiError)
          .finally(() => {this.loading = false})
      }
    }
  }
</script>
