<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->
<template>
  <div>
    <v-card-title class="px-0 text-md-h4 text-h6 primary--text word-bw ">Choisir le nom de mon espace</v-card-title>

    <v-card-subtitle class="px-0 op-60 text-md-body-2 text-caption">
      Le nom de l'espace sera visible par tous les membres de celui-ci et déterminera l'url pour y acceder.
    </v-card-subtitle>

    <div class="mt-3">
      <core-valid-text-field
        v-model="form.name.value"
        label="Saisissez le nom de votre nouvel espace" solo flat
        :loading="form.name.loading"
        class="elevation-x mb-3"
        :rules="nameRules"
        hide-details="auto"

        @input="value => {fetchSlug(value, 'name')}"
      />

      <core-valid-text-field
        v-if="editingSlug"
        v-model="form.slug.value"
        label="Saisissez l'adresse souhaitée pour votre espace"
        :loading="form.slug.loading"
        :disabled="form.name.loading"
        solo flat
        class="elevation-x mb-3"
        type="slug"
        suffix=".medicalspace.fr"
        :rules="slugRules"
        hide-details="auto"
        @input="value => fetchSlug(value, 'slug')"
      />

      <v-alert v-if="slugUnavailable" dense text transition="scale-transition" type="error">
        L'adresse internet
        <b class="font-weight-bold">{{ form.slug.value }}.mspace.fr</b>
        est indisponible.
      </v-alert>

      <v-alert v-if="slug" dense text transition="scale-transition" type="info">
        <v-row>
          <v-col cols="9" class="grow text-md-body-2 text-caption d-md-flex align-md-center">
            L'adresse internet de votre espace sera
            <b class="ml-1 font-weight-bold info--text"> {{ slug }}.mspace.fr</b>
          </v-col>
          <v-spacer />
          <v-btn v-if="!editingSlug" text fab x-small color="info lighten-1" class="my-auto" @click="editingSlug = true">
            <font-awesome-icon size="lg" :icon="['fad', 'pencil']" />
          </v-btn>
        </v-row>
      </v-alert>
    </div>

    <div class="d-flex align-center mt-5 justify-end">
      <v-btn color="secondary" depressed :disabled="!valid || form.name.loading || form.slug.loading" @click="$emit('next')">
        Suivant
        <v-icon right dark>$next</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
  import {debounce} from 'throttle-debounce'
  import {empty} from '@/utils/variables'
  import {generateSlug} from '@/modules/spaces/api'
  import CoreValidTextField from '@/modules/core/components/CoreValidTextField'
  import {rules} from '@/services/rules'

  export default {
    name: 'SpaceFormName',
    components: {CoreValidTextField},
    props: {
      name: String,
      slug: String
    },
    data() {
      return {
        editingSlug: false,
        slugUnavailable: false,
        form: {
          name: {
            value: this.name,
            loading: false
          },
          slug: {
            value: this.slug,
            loading: false
          }
        },
        nameRules: [
          rules.required,
          rules.minLength(3),
          rules.maxLength(30),
          rules.pattern(/^[A-Za-z0-9À-Öà-ö\s_-]+$/, 'La valeur ne peut contenir de caractères spéciaux.')
        ],
        slugRules: [
          rules.required,
          rules.minLength(3),
          rules.maxLength(10),
          rules.pattern(/^[0-9a-z_-]+$/, 'La valeur ne peut contenir que des chiffres, lettres en minuscule, underscore ou tirets.')
        ]
      }
    },
    computed: {
      valid() {
        return !empty(this.name) && !empty(this.slug)
      }
    },
    watch: {
      ['form.name.value'](value) {
        this.$emit('update:name', value)
      },
      ['form.slug.value']() {
        this.slugUnavailable = false
      }
    },
    methods: {
      fetchSlug: debounce(800, async function (value, field) {
        if (empty(value) || (field === 'slug' && value === this.slug)) {
          return
        }

        try {
          this.form[field].loading = true
          let {data} = await generateSlug(value)

          if ('name' === field) {
            this.form.slug.value = data.slug
            this.$emit('update:slug', data.slug)
          } else if ('slug' === field) {
            if (data.slug === value) {
              this.$emit('update:slug', data.slug)
            } else {
              this.slugUnavailable = true
            }
          }
        } catch (e) {
          this.$handleApiError(e)
        } finally {
          this.form[field].loading = false
        }
      })
    }
  }
</script>

