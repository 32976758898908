<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div>
    <v-card-title class="px-0 text-md-h4 text-h6 primary--text word-bw">Sélectionner ou créer un établissement</v-card-title>
    <v-card-subtitle class="px-0 op-60 text-md-body-2 text-caption">
      Je souhaite sélectionner ou créer un établissement à relier à mon espace.
    </v-card-subtitle>

    <div class="d-flex">
      <establishment-select-field v-model="localValue" />
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="px-0 new-establishments" elevation="0" v-bind="attrs" @click="$emit('create')" v-on="on">
            <font-awesome-icon fixed-width size="lg" :icon="['fal', 'plus']" class="grey--text text--lighten-1" />
          </v-btn>
        </template>
        <span>Créer un nouvel établissement</span>
      </v-tooltip>
    </div>

    <div class="d-flex align-center mt-5 justify-space-between flex-wrap">
      <div class="ml-1 mb-2 primary--text text-body-2 c-pointer" @click="$emit('skip')">
        Passer cette étape pour le moment
      </div>
      <div class="d-flex align-center justify-end">
        <v-btn :color="$vuetify.theme.dark ? '' :'grey lighten-3'" class="mr-2" depressed @click="$emit('prev')">
          <v-icon left dark>$prev</v-icon>
          Précédent
        </v-btn>
        <v-btn color="secondary" depressed :disabled="!localValue" @click="next">
          Suivant
          <v-icon right dark>$next</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  import EstablishmentSelectField from '@/modules/establishments/components/EstablishmentSelectField'

  export default {
    name: 'SpaceFormEstablishmentSelect',
    components: {EstablishmentSelectField},
    props: ['value'],
    data() {
      return {
        localValue: this.value
      }
    },
    methods: {
      next() {
        this.$emit('input', this.localValue)
        this.$emit('next')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .new-establishments {
    border: 1px dashed #ccc;
    height: 48px !important;
  }
</style>
